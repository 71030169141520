import axios from "axios";
import Web3 from "web3";
import { MetaMask } from "./wallets/MetaMask";
import Erc20AAbi from "../../contractAbi/erc20";

export const getShortAccountAddr = (addr) =>
  addr.slice(0, 5) + "..." + addr.slice(addr.length - 4);

let chainList;
export const getChainDetails = async (chainId) => {
  if (!chainList) {
    const chainListReq = await axios.get("https://chainid.network/chains.json");

    chainList = chainListReq.data;
  }

  return chainList.filter(
    (chainDetails) => chainDetails.chainId === chainId,
  )[0];
};

export const getChainCurrencyDecimals = async (chainId) => {
  const chainDetails = await getChainDetails(chainId);

  return chainDetails.nativeCurrency.decimals;
};

export const getAccountNativeCurrencyBalance = async ({
  accountAddr,
  rpcUrl,
}) => {
  const web3 = new Web3(rpcUrl);
  return await web3.eth.getBalance(accountAddr);
};

export const getAccountErc20TokenBalance = async ({
  accountAddr,
  tokenAddr,
  rpcUrl,
}) => {
  const web3 = new Web3(rpcUrl);
  const contract = new web3.eth.Contract(Erc20AAbi, tokenAddr);
  return await contract.methods.balanceOf(accountAddr).call();
};

let wallet;
export const setWallet = (newWallet) => (wallet = newWallet);
export const getWallet = () => wallet;

export const WalletTypes = {
  MetaMask: "MetaMask",
};

export const connectors = {
  [WalletTypes.MetaMask]: new MetaMask(),
};
