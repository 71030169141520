export const show = () => {
  document
    .getElementsByClassName("global-blocking-spinner")[0]
    .classList.remove("is-hidden");
};

export const hide = () => {
  document
    .getElementsByClassName("global-blocking-spinner")[0]
    .classList.add("is-hidden");
};
