import * as types from "./homeViewTypes";
import * as walletConnectorService from "../../components/walletConnector/walletConnectorService";
import * as protocolBridgeService from "../../services/protocolBridgeService";
import * as orchestratorApi from "../../services/orchestratorApi";
import { numberOfNotificationsVisibleInTopNavbar } from "../../env";
import { callUntilResult } from "../../services/utils";

export const getBridgeTokens = (appConfig) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_BRIDGE_TOKENS });

    const tokens = await orchestratorApi.getTokens(appConfig);

    dispatch({ type: types.GET_BRIDGE_TOKENS_SUCCESS, tokens });

    return [tokens];
  };
};

export const getAppConfig = () => {
  return async (dispatch) => {
    dispatch({ type: types.GET_APP_CONFIG });

    const appConfig = await orchestratorApi.getConfig();

    dispatch({ type: types.GET_APP_CONFIG_SUCCESS, appConfig });

    return [appConfig];
  };
};

export const getAccountBalanceOnBothNetworks = ({
  accountAddr,
  token,
  sourceNetworkConfig,
  targetNetworkConfig,
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ACCOUNT_BALANCE_ON_BOTH_NETWORKS,
      accountAddr,
      token,
      sourceNetworkConfig,
      targetNetworkConfig,
    });

    let sourceBalancePromise;
    let targetBalancePromise;

    if (sourceNetworkConfig.symbol === "OLT") {
      sourceBalancePromise =
        await walletConnectorService.getAccountNativeCurrencyBalance({
          accountAddr,
          rpcUrl: sourceNetworkConfig.rpc_url,
        });

      targetBalancePromise =
        await walletConnectorService.getAccountErc20TokenBalance({
          accountAddr,
          tokenAddr: token.address,
          rpcUrl: targetNetworkConfig.rpc_url,
        });
    } else {
      sourceBalancePromise =
        await walletConnectorService.getAccountErc20TokenBalance({
          accountAddr,
          tokenAddr: token.address,
          rpcUrl: sourceNetworkConfig.rpc_url,
        });

      targetBalancePromise =
        await walletConnectorService.getAccountNativeCurrencyBalance({
          accountAddr,
          rpcUrl: targetNetworkConfig.rpc_url,
        });
    }

    let [sourceBalance, targetBalance] = await Promise.all([
      sourceBalancePromise,
      targetBalancePromise,
    ]);

    sourceBalance = sourceBalance / 10 ** token.decimals;
    targetBalance = targetBalance / 10 ** token.decimals;

    dispatch({
      type: types.GET_ACCOUNT_BALANCE_ON_BOTH_NETWORKS_SUCCESS,
      sourceBalance,
      targetBalance,
    });
  };
};

export const switchNetworks = (walletType, chainId) => {
  return async (dispatch) => {
    dispatch({ type: types.SWITCH_NETWORKS, walletType, chainId });

    const [, error] = await walletConnectorService.connectors[
      walletType
    ].connect(chainId);

    if (error) {
      return [false, error];
    }

    dispatch({ type: types.SWITCH_NETWORKS_SUCCESS });
    return [true];
  };
};

export const getUsdValue = (amount, tokenSymbol) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_USD_VALUE, amount, tokenSymbol });

    const usdValue = orchestratorApi.getTokenUsdValue(amount, tokenSymbol);

    dispatch({ type: types.GET_USD_VALUE_SUCCESS, usdValue });
    return usdValue;
  };
};

export const showTransferModal = ({
  walletType,
  amount,
  sourceNetwork,
  targetNetwork,
  walletAccount,
  exitGasEstimate,
  token,
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.SHOW_TRANSFER_MODAL,
      walletType,
      amount,
      sourceNetwork,
      targetNetwork,
      walletAccount,
      exitGasEstimate,
    });

    // First ensure we are connected to the correct network on the wallet app
    await walletConnectorService.connectors[walletType].connect(
      sourceNetwork.config.chain_id,
    );

    const sourceNetworkName = sourceNetwork.name;
    const targetNetworkName = targetNetwork.name;
    const sourceWeb3 = walletConnectorService.getWallet();
    const amountNum = parseFloat(amount);
    const amountInWei = sourceWeb3.utils.toWei(amount.toString());
    const targetChainId = targetNetwork.config.chain_id;

    const sourceNetworkFeePromise =
      protocolBridgeService.getNetworkEnterTransactionFees({
        networkConfig: sourceNetwork.config,
        web3Instance: sourceWeb3,
        token,
        amountInWei,
        targetChainId,
        accountAddress: walletAccount.addr,
      });

    const targetNetworkFeePromise =
      protocolBridgeService.getNetworkExitTransactionFees({
        networkConfig: targetNetwork.config,
        exitGasEstimate,
      });

    const tokenPricePerUsdRatePromise =
      orchestratorApi.getTokenPricePerUsdRates();
    const [sourceNetworkFee, targetNetworkFee, tokenPricePerUsdRate] =
      await Promise.all([
        sourceNetworkFeePromise,
        targetNetworkFeePromise,
        tokenPricePerUsdRatePromise,
      ]);

    const sourceNetworkTokenSymbol = sourceNetwork.config.symbol;
    const targetNetworkTokenSymbol = targetNetwork.config.symbol;

    const usdRoundingDigits = 2;
    const amountInUsd = (
      amountNum * tokenPricePerUsdRate["OLT"]
    ).toFixed(usdRoundingDigits);
    const sourceGasInUsd = (
      sourceNetworkFee * tokenPricePerUsdRate[sourceNetworkTokenSymbol]
    ).toFixed(usdRoundingDigits);
    const targetGasInUsd = (
      targetNetworkFee * tokenPricePerUsdRate[targetNetworkTokenSymbol]
    ).toFixed(usdRoundingDigits);

    const amountAfterFees = (sourceNetworkName === "Ethereum") ? amountNum : amountNum - sourceNetworkFee;
    const amountAfterFeesInUsd = (amountAfterFees * tokenPricePerUsdRate["OLT"]).toFixed(usdRoundingDigits);

    const totalFeesInUsd = +sourceGasInUsd + +targetGasInUsd;

    dispatch(setTransferStep(transferSteps.review, false));

    dispatch({
      type: types.SHOW_TRANSFER_MODAL_SUCCESS,
      sourceNetworkName,
      targetNetworkName,
      amount,
      amountInUsd,
      sourceNetworkFee,
      targetNetworkFee,
      totalFeesInUsd,
      amountAfterFees,
      amountAfterFeesInUsd,
    });
  };
};

export const hideTransferModal = () => ({
  type: types.HIDE_TRANSFER_MODAL,
});

export const callSourceNetworkEnterMethod = ({
  walletType,
  sourceNetworkConfig,
  token,
  amount,
  targetChainId,
  accountAddress,
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.CALL_SOURCE_NETWORK_ENTER_METHOD,
      walletType,
      sourceNetworkConfig,
      amount,
      accountAddress,
    });

    // First ensure we are connected to the correct network on the wallet app
    await walletConnectorService.connectors[walletType].connect(
      sourceNetworkConfig.chain_id,
    );

    let enterTxDetails;

    try {
      const sourceWeb3 = walletConnectorService.getWallet();
      const amountInWei = sourceWeb3.utils.toWei(amount.toString());
      const sourceProtocolBridge =
        protocolBridgeService.getProtocolBridgeContract(
          sourceWeb3,
          sourceNetworkConfig.bridge_address,
        );
      const sourceBridgeEnterMethod =
        sourceNetworkConfig.symbol === "OLT" ? "enterETH" : "enter";
      const sourceEnterParams =
        sourceBridgeEnterMethod === "enter"
          ? [token.address, amountInWei, targetChainId]
          : [targetChainId];

      enterTxDetails = await sourceProtocolBridge.methods[
        sourceBridgeEnterMethod
      ](...sourceEnterParams).send({
        from: accountAddress,
        value: sourceBridgeEnterMethod === "enter" ? undefined : amountInWei,
      });
    } catch (err) {
      console.log("callSourceNetworkEnterMethod error", err);
      // TODO refactor so that the code is independent of MetaMask
      const errObj = {
        code: "user_rejected_transaction",
        message: "User rejected the transaction",
      };

      throw errObj;
    }

    dispatch({
      type: types.CALL_SOURCE_NETWORK_ENTER_METHOD_SUCCESS,
      enterTxDetails,
    });
    return enterTxDetails;
  };
};

export const checkCosignersStatus = (accountAddress, enterTx) => {
  return async (dispatch) => {
    dispatch({ type: types.CHECK_COSIGNERS_STATUS, accountAddress, enterTx });

    const [isReady, status] = await orchestratorApi.isEnterTxReadyToExit(
      accountAddress,
      enterTx,
    );

    dispatch({ type: types.CHECK_COSIGNERS_STATUS_RETURNED, isReady, status });
    return [isReady, status];
  };
};

export const callTargetNetworkExitMethod = ({
  walletType,
  targetNetworkConfig,
  accountAddress,
  signedData,
  signatures,
}) => {
  return async (dispatch) => {
    dispatch({ type: types.CALL_TARGET_NETWORK_EXIT_METHOD });

    const [targetWeb3] = await walletConnectorService.connectors[
      walletType
    ].connect(targetNetworkConfig.chain_id);

    let exitTxDetails;
    try {
      const targetProtocolBridge =
        protocolBridgeService.getProtocolBridgeContract(
          targetWeb3,
          targetNetworkConfig.bridge_address,
        );

      exitTxDetails = await targetProtocolBridge.methods
        .exit(signedData, signatures)
        .send({
          from: accountAddress,
        });
    } catch (err) {
      console.log("callTargetNetworkExitMethod error", err);
      // TODO refactor so that the code is independent of MetaMask
      // TODO catch other types of error like for ex: network issue
      const errObj = {
        code: "user_rejected_transaction",
        message: "User rejected the transaction",
      };

      throw errObj;
    }

    dispatch({
      type: types.CALL_TARGET_NETWORK_EXIT_METHOD_SUCCESS,
      exitTx: exitTxDetails,
    });
    return exitTxDetails;
  };
};

export const checkTransferStatus = (accountAddress, exitTx) => {
  return async (dispatch) => {
    dispatch({ type: types.CHECK_TRANSFER_STATUS, accountAddress, exitTx });

    const [isReady, status] = await orchestratorApi.isExitTxSuccessful(
      accountAddress,
      exitTx,
    );

    dispatch({ type: types.CHECK_TRANSFER_STATUS_RETURNED, isReady, status });
    return [isReady, status];
  };
};

export const transferSteps = {
  review: "review",
  sourceNetwork: "sourceNetwork",
  cosigners: "cosigners",
  targetNetwork: "targetNetwork",
  confirmations: "confirmations",
  done: "done",
};

export const setTransferStep = (currentStep, isStepLoading) => ({
  type: types.SET_CURRENT_TRANSFER_STEP,
  currentStep,
  isStepLoading,
});

export const startTransfer = ({
  walletType,
  sourceNetwork,
  targetNetwork,
  accountAddress,
  transferAmount,
  token,
  showTransactionsViewModalWhenDone = false,
  context = {},
  startingStep = transferSteps.sourceNetwork,
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.START_TRANSFER,
      walletType,
      sourceNetwork,
      targetNetwork,
      accountAddress,
      transferAmount,
      token,
      showTransactionsViewModalWhenDone,
      context,
      startingStep,
    });
    context.statusCheckDelay = 1e3;

    const steps = [
      {
        step: transferSteps.sourceNetwork,
        isStepLoading: true,
        method: async () => {
          const enterTxDetails = await dispatch(
            callSourceNetworkEnterMethod({
              walletType,
              sourceNetworkConfig: sourceNetwork.config,
              amount: transferAmount,
              targetChainId: targetNetwork.config.chain_id,
              accountAddress,
              token,
            }),
          );

          return { enterTxDetails };
        },
      },
      {
        step: transferSteps.cosigners,
        isStepLoading: true,
        method: async (context) => {
          const cosignersStatusCheckMethod = checkCosignersStatus(
            accountAddress,
            context.enterTxDetails.transactionHash,
          );
          const [, cosignersStatus] = await callUntilResult(
            () => dispatch(cosignersStatusCheckMethod),
            ([isReady]) => isReady,
            context.statusCheckDelay,
          );

          return { cosignersStatus };
        },
      },
      {
        step: transferSteps.targetNetwork,
        isStepLoading: true,
        method: async (context) => {
          const exitTxDetails = await dispatch(
            callTargetNetworkExitMethod({
              walletType,
              targetNetworkConfig: targetNetwork.config,
              accountAddress,
              signedData: context.cosignersStatus.signed.data,
              signatures: context.cosignersStatus.signed.signatures,
            }),
          );

          return { exitTxDetails };
        },
      },
      {
        step: transferSteps.confirmations,
        isStepLoading: true,
        method: async (context) => {
          const transferStatusCheckMethod = checkTransferStatus(
            accountAddress,
            context.exitTxDetails.transactionHash,
          );
          await callUntilResult(
            () => dispatch(transferStatusCheckMethod),
            ([isSuccessful]) => isSuccessful,
            context.statusCheckDelay,
          );
        },
      },
      {
        step: transferSteps.done,
        isStepLoading: false,
        method: async () => {
          const accountBalancesPromise = dispatch(
            getAccountBalanceOnBothNetworks({
              accountAddr: accountAddress,
              token,
              sourceNetworkConfig: sourceNetwork.config,
              targetNetworkConfig: targetNetwork.config,
            }),
          );

          const pastTransactionsPromise = dispatch(
            getPastTransactions({
              accountAddr: accountAddress,
              sourceNetwork,
              targetNetwork,
            }),
          );

          return Promise.all([accountBalancesPromise, pastTransactionsPromise]);
        },
      },
    ];

    try {
      const startingStepIndex = steps.findIndex(
        (curStep) => curStep.step === startingStep,
      );

      for (let i = startingStepIndex; i < steps.length; i++) {
        const curStep = steps[i];

        dispatch(setTransferStep(curStep.step, curStep.isStepLoading));
        const result = await curStep.method(context);
        Object.assign(context, result);
      }
    } catch (err) {
      dispatch({ type: types.TRANSFER_ERROR, err, context });
      return;
    }

    dispatch({ type: types.END_TRANSFER, showTransactionsViewModalWhenDone });
  };
};

export const getPastTransactions = ({
  accountAddr,
  sourceNetwork,
  targetNetwork,
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_PAST_TRANSACTIONS,
      accountAddr,
      sourceNetwork,
      targetNetwork,
    });

    const chainIdNetworkMap = {
      [sourceNetwork.config.chain_id]: sourceNetwork,
      [targetNetwork.config.chain_id]: targetNetwork,
    };
    const formatTransactionObj = (obj) => ({
      status: obj.status !== "SUCCESS" ? "Pending" : "Success",
      sourceTx: obj.source.tx_hash,
      amount: obj.amount.value / 10 ** obj.amount.decimals,
      sourceNetwork: chainIdNetworkMap[obj.source.chain_id],
      targetNetwork: chainIdNetworkMap[obj.target.chain_id],
      date: {
        raw: new Date(obj.source.created_at),
        formatted: new Date(obj.source.created_at).toDateString(),
      },
    });
    const pastTransactions = await orchestratorApi.getAccountTransactions(
      accountAddr,
    );
    const latestTransactions = pastTransactions
      .slice(0, numberOfNotificationsVisibleInTopNavbar)
      .map(formatTransactionObj);
    const unfinishedTransactions = pastTransactions
      .filter((transaction) => transaction.status !== "SUCCESS")
      .map(formatTransactionObj);

    dispatch({
      type: types.GET_PAST_TRANSACTIONS_SUCCESS,
      latestTransactions,
      unfinishedTransactions,
    });
    return { latestTransactions, unfinishedTransactions };
  };
};

export const showTransactionsViewModal = ({ title, transactionsList }) => ({
  type: types.SHOW_TRANSACTIONS_VIEW_MODAL,
  title,
  transactionsList,
});

export const hideTransactionsViewModal = () => ({
  type: types.HIDE_TRANSACTIONS_VIEW_MODAL,
});
