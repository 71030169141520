export const GET_BRIDGE_TOKENS = "HOME_VIEW/GET_BRIDGE_TOKENS";
export const GET_BRIDGE_TOKENS_SUCCESS = "HOME_VIEW/GET_BRIDGE_TOKENS_SUCCESS";
export const GET_APP_CONFIG = "HOME_VIEW/GET_APP_CONFIG";
export const GET_APP_CONFIG_SUCCESS = "HOME_VIEW/GET_APP_CONFIG_SUCCESS";
export const SWITCH_NETWORKS = "HOME_VIEW/SWITCH_NETWORKS";
export const SWITCH_NETWORKS_SUCCESS = "HOME_VIEW/SWITCH_NETWORKS_SUCCESS";
export const GET_ACCOUNT_BALANCE_ON_BOTH_NETWORKS =
  "HOME_VIEW/GET_ACCOUNT_BALANCE_ON_BOTH_NETWORKS";
export const GET_ACCOUNT_BALANCE_ON_BOTH_NETWORKS_SUCCESS =
  "HOME_VIEW/GET_ACCOUNT_BALANCE_ON_BOTH_NETWORKS_SUCCESS";
export const GET_USD_VALUE = "HOME_VIEW/GET_USD_VALUE";
export const GET_USD_VALUE_SUCCESS = "HOME_VIEW/GET_USD_VALUE_SUCCESS";
export const SHOW_TRANSFER_MODAL = "HOME_VIEW/SHOW_TRANSFER_MODAL";
export const SHOW_TRANSFER_MODAL_SUCCESS =
  "HOME_VIEW/SHOW_TRANSFER_MODAL_SUCCESS";
export const HIDE_TRANSFER_MODAL = "HOME_VIEW/HIDE_TRANSFER_MODAL";
export const CALL_SOURCE_NETWORK_ENTER_METHOD =
  "HOME_VIEW/CALL_SOURCE_NETWORK_ENTER_METHOD";
export const CALL_SOURCE_NETWORK_ENTER_METHOD_SUCCESS =
  "HOME_VIEW/CALL_SOURCE_NETWORK_ENTER_METHOD_SUCCESS";
export const CHECK_COSIGNERS_STATUS = "HOME_VIEW/CHECK_COSIGNERS_STATUS";
export const CHECK_COSIGNERS_STATUS_RETURNED =
  "HOME_VIEW/CHECK_COSIGNERS_STATUS_RETURNED";
export const CALL_TARGET_NETWORK_EXIT_METHOD =
  "HOME_VIEW/CALL_TARGET_NETWORK_EXIT_METHOD";
export const CALL_TARGET_NETWORK_EXIT_METHOD_SUCCESS =
  "HOME_VIEW/CALL_TARGET_NETWORK_EXIT_METHOD_SUCCESS";
export const CHECK_TRANSFER_STATUS = "HOME_VIEW/CHECK_TRANSFER_STATUS";
export const CHECK_TRANSFER_STATUS_RETURNED =
  "HOME_VIEW/CHECK_TRANSFER_STATUS_RETURNED";
export const SET_CURRENT_TRANSFER_STEP = "HOME_VIEW/SET_CURRENT_TRANSFER_STEP";
export const GET_PAST_TRANSACTIONS = "HOME_VIEW/GET_PAST_TRANSACTIONS";
export const GET_PAST_TRANSACTIONS_SUCCESS =
  "HOME_VIEW/GET_PAST_TRANSACTIONS_SUCCESS";
export const SHOW_TRANSACTIONS_VIEW_MODAL =
  "HOME_VIEW/SHOW_TRANSACTIONS_VIEW_MODAL";
export const HIDE_TRANSACTIONS_VIEW_MODAL =
  "HOME_VIEW/HIDE_TRANSACTIONS_VIEW_MODAL";
export const START_TRANSFER = "HOME_VIEW/START_TRANSFER";
export const TRANSFER_ERROR = "HOME_VIEW/TRANSFER_ERROR";
export const END_TRANSFER = "HOME_VIEW/END_TRANSFER";
