import BridgeRouter from "../contractAbi/BridgeRouter.json";
import Web3 from "web3";

export const getProtocolBridgeContract = (
  web3Instance,
  protocolBridgeAddress,
) => new web3Instance.eth.Contract(BridgeRouter.abi, protocolBridgeAddress);

export const getNetworkEnterTransactionFees = async ({
  networkConfig,
  web3Instance,
  token,
  amountInWei,
  targetChainId,
  accountAddress,
}) => {
  const sourceProtocolBridgePromise = getProtocolBridgeContract(
    web3Instance,
    networkConfig.bridge_address,
  );
  const sourceGasPricePromise = web3Instance.eth.getGasPrice();
  const [sourceProtocolBridge, sourceGasPrice] = await Promise.all([
    sourceProtocolBridgePromise,
    sourceGasPricePromise,
  ]);
  const sourceBridgeEnterMethod =
    networkConfig.symbol === "OLT" ? "enterETH" : "enter";
  const sourceEnterParams =
    sourceBridgeEnterMethod === "enter"
      ? [token.address, amountInWei, targetChainId]
      : [targetChainId];

  let sourceEnterEstimatedGas = await sourceProtocolBridge.methods[
    sourceBridgeEnterMethod
  ](...sourceEnterParams).estimateGas({
    from: accountAddress,
    value: sourceBridgeEnterMethod === "enter" ? undefined : amountInWei,
  });
  const sourceEstimateTransactionFeeWei =
    sourceEnterEstimatedGas * sourceGasPrice;

  return parseFloat(
    web3Instance.utils.fromWei(
      sourceEstimateTransactionFeeWei.toString(),
      "ether",
    ),
  );
};

export const getNetworkExitTransactionFees = async ({
  networkConfig,
  exitGasEstimate,
}) => {
  const web3Instance = new Web3(networkConfig.rpc_url);
  const targetGasPrice = await web3Instance.eth.getGasPrice();

  return parseFloat(
    web3Instance.utils.fromWei(
      (targetGasPrice * exitGasEstimate).toString(),
      "ether",
    ),
  );
};
