import axios from "axios";
import { orchestratorApiBase } from "../env";

export const requestStatus = {
  OK: "OK",
};

export const getTokens = async (config) => {
  const response = await axios.get(config.token_list_url);
  return response.data.tokens;
}

export const getConfig = async () =>
  (await axios.get(orchestratorApiBase + "/config/"))
    .data.result;

export const getTokenPricePerUsdRates = async () => {
  const result = {};
  (await axios.get(orchestratorApiBase + "/prices/")).data.results.forEach(
    (priceData) => (result[priceData.symbol] = priceData.price_per_usd),
  );

  return result;
};

export const getTokenUsdValue = async (amount, tokenSymbol) => {
  const tokenPricePerUsdRate = (await getTokenPricePerUsdRates())[tokenSymbol];
  return amount * tokenPricePerUsdRate;
};

export const isEnterTxReadyToExit = async (accountAddr, enterTx) => {
  const enterTxStatus = (
    await axios.get(
      orchestratorApiBase + `/wallet/${accountAddr}/txs/${enterTx}`,
    )
  ).data.result;
  const isEnterTxReady =
    enterTxStatus && enterTxStatus.status === "READY_TO_EXIT";

  return [isEnterTxReady, enterTxStatus];
};

export const isExitTxSuccessful = async (accountAddr, exitTx) => {
  const exitTxStatus = (
    await axios.get(
      orchestratorApiBase + `/wallet/${accountAddr}/txs/${exitTx}`,
    )
  ).data.result;
  const isExitTxSuccessful = exitTxStatus && exitTxStatus.status === "SUCCESS";

  return [isExitTxSuccessful, exitTxStatus];
};

export const getAccountTransactions = async (accountAddr) =>
  (await axios.get(orchestratorApiBase + `/wallet/${accountAddr}/txs`)).data
    .results;
