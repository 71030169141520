import { combineReducers } from "redux";
import walletConnector from "../../components/walletConnector/walletConnectorReducer";
import homeView from "../../views/home/homeViewReducer";

const rootReducer = combineReducers({
  walletConnector,
  homeView,
});

export default rootReducer;
