import { transferSteps } from "./homeViewActions";

const homeViewInitialState = {
  appConfig: {},
  sourceNetwork: {
    config: {},
    name: "OneLedger",
    logoPath: "oneledger.png",
    highlightClass: "highlighted-network",
    balance: 0,
    confirmations: 1,
  },
  targetNetwork: {
    config: {},
    name: "Ethereum",
    logoPath: "ethereum.png",
    highlightClass: "",
    balance: 0,
    confirmations: 12,
  },
  latestTransactions: [],
  unfinishedTransactions: [],
  isTransferBtnLoading: false,
  isTokenAdditionRequestPending: false,
  transactionsViewModal: {
    isModalActive: false,
    modalTitle: "",
    transactionsList: [],
  },
  transferStatus: {
    isTransferModalActive: false,
    isTransferProcessOngoing: false,
    isTransferComplete: false,
    currentStep: transferSteps.review,
    isStepLoading: false,
    sourceNetworkName: "",
    targetNetworkName: "",
    amount: 0,
    amountInUsd: 0,
    sourceNetworkFee: 0,
    targetNetworkFee: 0,
    totalFeesInUsd: 0,
    amountAfterFeesInUsd: 0,
    amountAfterFees: 0,
    showTransactionsViewModalWhenDone: false,
    // { code: '', message: '' }
    transferErr: undefined,
    // Used to resume the transfer when it fails for whatever reason
    currentTransferContext: undefined,
  },
};

export default homeViewInitialState;
