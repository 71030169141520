import React, { useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MetaMaskRoundLogo from "../../images/metamask-round-logo.svg";
import { WalletTypes } from "./walletConnectorService";
import { toast } from "react-hot-toast";
import {
  connectWallet,
  hideWalletSelectionModal,
} from "./walletConnectorActions";

const WalletConnector = (props) => {
  const {
    isWalletSelectionModalActive,
    chainId,
    walletConnectionState,
    connectWallet,
    hideWalletSelectionModal,
  } = props;

  const getWalletConnector = useCallback((walletType) => async () => {
    const [, error] = await connectWallet({ walletType, chainId });

    if (error) {
      toast.error(error.message);
      return;
    }

    localStorage.setItem("session:wallet", JSON.stringify({ type: walletType, chainId }));

    hideWalletSelectionModal();
  }, [chainId, connectWallet, hideWalletSelectionModal]);

  const getWalletLoadingClass = (walletType) =>
    walletConnectionState.walletType === walletType &&
      walletConnectionState.isConnectionPending
      ? "is-loading"
      : "";

  return (
    <div className={`modal ${isWalletSelectionModalActive ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Connect Wallet</p>
          <button
            className="delete"
            aria-label="close"
            onClick={hideWalletSelectionModal}
          ></button>
        </header>
        <section className="modal-card-body">
          <button
            className={`button py-5 is-outlined is-fullwidth is-justify-content-start ${getWalletLoadingClass(
              WalletTypes.MetaMask,
            )}`}
            style={{ borderRadius: "24px" }}
            onClick={getWalletConnector(WalletTypes.MetaMask, chainId)}
          >
            <img src={MetaMaskRoundLogo} width="40" alt="MetaMask Logo" />
            &nbsp;&nbsp;MetaMask
          </button>
        </section>
      </div>
    </div>
  );
};

WalletConnector.propTypes = {
  isWalletSelectionModalActive: PropTypes.bool,
  chainId: PropTypes.number,
  wallet: PropTypes.object,
  walletConnectionState: PropTypes.shape({
    isConnectionPending: PropTypes.bool,
    walletType: PropTypes.string,
  }),
  connectWallet: PropTypes.func,
  hideWalletSelectionModal: PropTypes.func,
};

export default connect(
  (state) => ({
    isWalletSelectionModalActive:
      state.walletConnector.isWalletSelectionModalActive,
    chainId: state.walletConnector.chainId,
    wallet: state.walletConnector.wallet,
    walletConnectionState: state.walletConnector.walletConnectionState,
  }),
  (dispatch) => ({
    ...bindActionCreators(
      {
        connectWallet,
        hideWalletSelectionModal,
      },
      dispatch,
    ),
  }),
)(WalletConnector);
