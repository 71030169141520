import homeViewInitialState from "./homeViewInitialState";
import {
  GET_BRIDGE_TOKENS_SUCCESS,
  GET_APP_CONFIG,
  GET_APP_CONFIG_SUCCESS,
  GET_ACCOUNT_BALANCE_ON_BOTH_NETWORKS_SUCCESS,
  SWITCH_NETWORKS_SUCCESS,
  SHOW_TRANSFER_MODAL_SUCCESS,
  HIDE_TRANSFER_MODAL,
  SHOW_TRANSFER_MODAL,
  CHECK_TRANSFER_STATUS_RETURNED,
  CALL_SOURCE_NETWORK_ENTER_METHOD,
  SET_CURRENT_TRANSFER_STEP,
  GET_PAST_TRANSACTIONS_SUCCESS,
  SHOW_TRANSACTIONS_VIEW_MODAL,
  HIDE_TRANSACTIONS_VIEW_MODAL,
  START_TRANSFER,
  TRANSFER_ERROR,
  END_TRANSFER,
} from "./homeViewTypes";
import {
  ADD_TOKEN_TO_WALLET,
  ADD_TOKEN_TO_WALLET_ERROR,
  ADD_TOKEN_TO_WALLET_SUCCESS,
} from "../../components/walletConnector/walletConnectorTypes";

const homeViewReducer = (state = homeViewInitialState, action) => {
  switch (action.type) {
    case GET_APP_CONFIG:
      return {
        ...state,
      };
    case GET_BRIDGE_TOKENS_SUCCESS:
      return {
        ...state,
        tokens: action.tokens,
      };
    case GET_APP_CONFIG_SUCCESS:
      return {
        ...state,
        appConfig: action.appConfig,
        sourceNetwork: {
          ...state.sourceNetwork,
          config: action.appConfig.networks[0].source,
        },
        targetNetwork: {
          ...state.targetNetwork,
          config: action.appConfig.networks[0].target,
        },
      };
    case GET_ACCOUNT_BALANCE_ON_BOTH_NETWORKS_SUCCESS:
      return {
        ...state,
        sourceNetwork: {
          ...state.sourceNetwork,
          balance: action.sourceBalance,
        },
        targetNetwork: {
          ...state.targetNetwork,
          balance: action.targetBalance,
        },
      };
    case SWITCH_NETWORKS_SUCCESS:
      return {
        ...state,
        sourceNetwork: {
          ...state.targetNetwork,
          // Set the balance to 0 as we fetch the balance data again after
          // switching so if not set to 0, it will flash with data from the
          // opposing network before the request to get the balance is fulfilled
          // and correctly set the balance
          balance: 0,
        },
        targetNetwork: {
          ...state.sourceNetwork,
          balance: 0,
        },
      };
    case SHOW_TRANSFER_MODAL:
      return {
        ...state,
        isTransferBtnLoading: true,
      };
    case SHOW_TRANSFER_MODAL_SUCCESS:
      return {
        ...state,
        isTransferBtnLoading: false,
        transferStatus: {
          ...state.transferStatus,
          isTransferModalActive: true,
          isTransferComplete: false,
          sourceNetworkName: action.sourceNetworkName,
          targetNetworkName: action.targetNetworkName,
          amount: action.amount,
          amountInUsd: action.amountInUsd,
          sourceNetworkFee: action.sourceNetworkFee,
          targetNetworkFee: action.targetNetworkFee,
          totalFeesInUsd: action.totalFeesInUsd,
          amountAfterFees: action.amountAfterFees,
          amountAfterFeesInUsd: action.amountAfterFeesInUsd,
          transferErr: undefined,
          currentTransferContext: undefined,
        },
      };
    case HIDE_TRANSFER_MODAL:
      return {
        ...state,
        transferStatus: {
          ...state.transferStatus,
          isTransferModalActive: false,
          showTransactionsViewModalWhenDone: false,
        },
      };
    case CALL_SOURCE_NETWORK_ENTER_METHOD:
      return {
        ...state,
        transferStatus: {
          ...state.transferStatus,
          isTransferProcessOngoing: true,
        },
      };
    case CHECK_TRANSFER_STATUS_RETURNED:
      return {
        ...state,
        transferStatus: {
          ...state.transferStatus,
          isTransferProcessOngoing: false,
          isTransferComplete: true,
        },
      };
    case ADD_TOKEN_TO_WALLET:
      return {
        ...state,
        isTokenAdditionRequestPending: true,
      };
    case ADD_TOKEN_TO_WALLET_SUCCESS:
      return {
        ...state,
        isTokenAdditionRequestPending: false,
      };
    case ADD_TOKEN_TO_WALLET_ERROR:
      return {
        ...state,
        isTokenAdditionRequestPending: false,
      };
    case SET_CURRENT_TRANSFER_STEP:
      return {
        ...state,
        transferStatus: {
          ...state.transferStatus,
          currentStep: action.currentStep,
          isStepLoading: action.isStepLoading,
        },
      };
    case GET_PAST_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        latestTransactions: action.latestTransactions,
        unfinishedTransactions: action.unfinishedTransactions,
      };
    case SHOW_TRANSACTIONS_VIEW_MODAL:
      return {
        ...state,
        transactionsViewModal: {
          isModalActive: true,
          modalTitle: action.title,
          transactionsList: action.transactionsList,
        },
      };
    case HIDE_TRANSACTIONS_VIEW_MODAL:
      return {
        ...state,
        transactionsViewModal: {
          ...state.transactionsViewModal,
          isModalActive: false,
        },
      };
    case START_TRANSFER:
      return {
        ...state,
        transactionsViewModal: {
          ...state.transactionsViewModal,
          isModalActive: false,
        },
        transferStatus: {
          ...state.transferStatus,
          isTransferModalActive: true,
          isTransferProcessOngoing: true,
          isTransferComplete: false,
          sourceNetworkName: action.sourceNetwork.name,
          targetNetworkName: action.targetNetwork.name,
          amount: action.transferAmount,
          // Required to avoid showing a warning that balance cannot cover the
          // fees as we are just continuing a a previous transaction NOT a new
          // one so no need to calculate the fees again
          amountAfterFees: action.transferAmount,
          transferErr: undefined,
          currentTransferContext: undefined,
        },
      };
    case TRANSFER_ERROR:
      return {
        ...state,
        transferStatus: {
          ...state.transferStatus,
          isTransferProcessOngoing: false,
          transferErr: action.err,
          currentTransferContext: action.context,
        },
      };
    case END_TRANSFER:
      return {
        ...state,
        transferStatus: {
          ...state.transferStatus,
          isTransferProcessOngoing: false,
          isTransferComplete: true,
          showTransactionsViewModalWhenDone:
            action.showTransactionsViewModalWhenDone,
        },
      };
    default:
      return state;
  }
};

export default homeViewReducer;
