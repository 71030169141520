import walletConnectorInitialState from "./walletConnectorInitialState";
import {
  CONNECT_WALLET,
  CONNECT_WALLET_SUCCESS,
  CONNECT_WALLET_ERROR,
  SHOW_WALLET_SELECTION_MODAL,
  HIDE_WALLET_SELECTION_MODAL,
  ACCOUNT_CHANGED,
  SET_CONNECTED_WALLET_ACCOUNT_CHANGE_WATCHER,
} from "./walletConnectorTypes";

const walletConnectorReducer = (
  state = walletConnectorInitialState,
  action,
) => {
  switch (action.type) {
    case SHOW_WALLET_SELECTION_MODAL:
      return {
        ...state,
        isWalletSelectionModalActive: true,
        chainId: action.chainId,
      };
    case HIDE_WALLET_SELECTION_MODAL:
      return {
        ...state,
        isWalletSelectionModalActive: false,
      };
    case CONNECT_WALLET:
      return {
        ...state,
        walletConnectionState: {
          isConnectionPending: true,
          walletType: action.walletType,
        },
      };
    case CONNECT_WALLET_SUCCESS:
      return {
        ...state,
        wallet: action.wallet,
        accounts: action.accounts,
        walletType: action.walletType,
        currentWalletAccount: action.accounts[0],
        chainId: action.chainId,
        walletConnectionState: {
          isConnectionPending: false,
          walletType: "",
        },
      };
    case CONNECT_WALLET_ERROR:
      return {
        ...state,
        walletConnectionState: {
          isConnectionPending: false,
          walletType: "",
        },
      };
    case SET_CONNECTED_WALLET_ACCOUNT_CHANGE_WATCHER:
      return {
        ...state,
        isAccountChangeWatcherSet: true,
      };
    case ACCOUNT_CHANGED:
      return {
        ...state,
        currentWalletAccount: action.newAccount,
      };
    default:
      return state;
  }
};

export default walletConnectorReducer;
