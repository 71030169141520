import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./topNavbar.scss";
import metaMaskLogo from "../../images/metamask.png";
import { bindActionCreators } from "redux";
import {
  getAppConfig, startTransfer, transferSteps, getPastTransactions, showTransactionsViewModal,
} from "../../views/home/homeViewActions";
import { getOltHardcodedToken, isFloat } from "../../services/utils";
import useCheckTouchScreen from "../../components/useCheckTouchScreen";

const TopNavbar = (props) => {
  const {
    currentWalletAccount,
    latestTransactions,
    connectedWalletType,
    startTransfer,
    tokens,
    sourceNetwork,
    targetNetwork,
    unfinishedTransactions,
    getPastTransactions,
    showTransactionsViewModal,
  } = props;
  const currentToken = getOltHardcodedToken(tokens, sourceNetwork.config.chain_id, "OLT");

  const isTouchDevice = useCheckTouchScreen();

  useEffect(() => {
    if (!currentWalletAccount.addr) return;

    const id = setInterval(async () => {
      await getPastTransactions({
        accountAddr: currentWalletAccount.addr,
        sourceNetwork,
        targetNetwork,
      });
    }, 5000)
    return () => {
      clearInterval(id);
    }
  }, [currentWalletAccount, sourceNetwork, targetNetwork]);

  const getContinuationMethod = (transaction) => () => {
    startTransfer({
      walletType: connectedWalletType,
      sourceNetwork: transaction.sourceNetwork,
      targetNetwork: transaction.targetNetwork,
      accountAddress: currentWalletAccount.addr,
      transferAmount: transaction.amount,
      token: currentToken,
      context: { enterTxDetails: { transactionHash: transaction.sourceTx } },
      startingStep: transferSteps.cosigners,
    });
  }

  const getTransactionsItems = (transactionsList) =>
    transactionsList.map((transaction) => {
      return (
        <React.Fragment key={transaction.sourceTx}>
          <div className="dropdown-item columns">
            <div className="column">
              <i
                className={
                  `fa ${transaction.status === "Success"
                    ? "fa-check is-light-green"
                    : "fa fa-minus-square is-light-red"
                  }`}
                title={transaction.status}
              ></i>&nbsp;
              {isFloat(transaction.amount) ? transaction.amount.toFixed(4) : transaction.amount} OLT</div>
            <div className="column">
              {transaction.sourceNetwork.name}
              &nbsp;&nbsp;<i className="fa fa-arrow-right is-network-direction-arrow"></i>&nbsp;&nbsp;
              {transaction.targetNetwork.name}
            </div>
            <div className="column">
              {transaction.date.formatted}
              <br></br>
              <button
                className={
                  `button one-color-button is-small ${transaction.status === "Pending" ? "" : "is-hidden"
                  }`}
                style={{ borderRadius: "12px" }}
                disabled={!currentToken}
                title="Continue the Transaction"
                onMouseDown={getContinuationMethod(transaction)}
              >
                Resume tx
              </button>
            </div>
          </div>
          <hr className="dropdown-divider"></hr>
        </React.Fragment>
      );
    });

  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const [txDropdownActive, setTxDropdownActive] = useState(false);

  const onBurgerClick = () => {
    setIsBurgerActive(prev => !prev);
  };

  const onShowTxDropdown = (e) => {
    if (e.currentTarget === document.activeElement) {
      setTxDropdownActive(prev => !prev);
    }
    document.body.focus();
  };

  return (
    <nav
      className="navbar is-black"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link className="navbar-item" to={{ pathname: "/" }}>
          <img
            src="https://raw.githubusercontent.com/oneledger/syndicate-token-list/master/logo_full.svg"
            alt="Syndicate bridge Logo"
          />
        </Link>
        <a href="/#" onClick={onBurgerClick} role="button" className={`navbar-burger ${isBurgerActive ? "is-active" : ""}`} aria-label="menu" aria-expanded="false">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      {currentWalletAccount.addr && (
        <>
          {isTouchDevice ? (
            <div className={`navbar-menu ${isBurgerActive ? "is-active" : ""}`}>
              <div className="navbar-item">
                <div className="buttons">
                  <a
                    href="/#"
                    className="button is-primary is-outlined is-rounded"
                    title={`MetaMask Account: ${currentWalletAccount.addr}`}
                  >
                    <img src={metaMaskLogo} alt="MetaMask Logo" />
                    &nbsp;&nbsp;&nbsp;{currentWalletAccount.shortDisplay}
                    &nbsp;&nbsp;&nbsp;
                  </a>
                  <a
                    href="/#"
                    className="button is-primary is-outlined is-rounded"
                    title="History"
                    onClick={() => {
                      showTransactionsViewModal({
                        title: "Transaction history",
                        transactionsList: latestTransactions,
                      });
                    }}
                  >
                    History
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <>
              {!isTouchDevice && (
                <div className="navbar-menu">
                  <div className="navbar-end">
                    <>
                      {latestTransactions.length > 0 && (
                        <div className="navbar-item">
                          <div className={`dropdown ${txDropdownActive ? "is-active" : ""}`} onBlur={() => setTxDropdownActive(false)}>
                            <div className="dropdown-trigger notification-indicator">
                              <button className="button is-primary is-outlined is-rounded" aria-haspopup="true" aria-controls="dropdown-tx-items" style={{ borderRadius: "24px" }} onClick={onShowTxDropdown}>
                                <span className="icon is-small">
                                  {unfinishedTransactions.length > 0 ? (
                                    <i className="fas fa-exclamation-triangle" style={{ color: "orange" }}></i>
                                  ) : (
                                    <i className="far fa-bell"></i>
                                  )}
                                </span>
                              </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-tx-items" role="menu">
                              <div className="dropdown-content notification-content">
                                {getTransactionsItems(latestTransactions)}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="navbar-item">
                        <div className="buttons">
                          <a
                            href="/#"
                            className="button is-primary is-outlined is-rounded"
                            title={`MetaMask Account: ${currentWalletAccount.addr}`}
                          >
                            <img src={metaMaskLogo} alt="MetaMask Logo" />
                            &nbsp;&nbsp;&nbsp;{currentWalletAccount.shortDisplay}
                            &nbsp;&nbsp;&nbsp;
                          </a>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </nav >
  );
};

TopNavbar.propTypes = {
  currentWalletAccount: PropTypes.shape({
    addr: PropTypes.string,
    shortDisplay: PropTypes.string,
  }),
  latestTransactions: PropTypes.array,
  connectedWalletType: PropTypes.string,
  startTransfer: PropTypes.func,
  appConfig: PropTypes.object,
  getAppConfig: PropTypes.func,
  tokens: PropTypes.array,
  sourceNetwork: PropTypes.object,
  targetNetwork: PropTypes.object,
  unfinishedTransactions: PropTypes.array,
  getPastTransactions: PropTypes.func,
  showTransactionsViewModal: PropTypes.func,
};

export default connect(
  (state) => ({
    currentWalletAccount: state.walletConnector.currentWalletAccount,
    latestTransactions: state.homeView.latestTransactions,
    connectedWalletType: state.walletConnector.walletType,
    sourceNetwork: state.homeView.sourceNetwork,
    targetNetwork: state.homeView.targetNetwork,
    appConfig: state.homeView.appConfig,
    tokens: state.homeView.tokens,
    chainId: state.homeView.chainId,
    unfinishedTransactions: state.homeView.unfinishedTransactions,
  }),
  (dispatch) => ({
    ...bindActionCreators(
      {
        startTransfer,
        getAppConfig,
        getPastTransactions,
        showTransactionsViewModal,
      },
      dispatch,
    ),
  }),
)(TopNavbar);
