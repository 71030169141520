import * as types from "./walletConnectorTypes";
import * as walletConnectorService from "./walletConnectorService";

export const showWalletSelectionModal = (chainId) => ({
  type: types.SHOW_WALLET_SELECTION_MODAL,
  chainId,
});

export const hideWalletSelectionModal = () => ({
  type: types.HIDE_WALLET_SELECTION_MODAL,
});

export const connectWallet = ({ walletType, chainId }) => {
  return async (dispatch) => {
    dispatch({ type: types.CONNECT_WALLET, walletType });

    const [wallet, error] = await walletConnectorService.connectors[
      walletType
    ].connect(chainId);

    if (error) {
      dispatch(connectWalletError());
      return [undefined, error];
    }

    walletConnectorService.setWallet(wallet);

    const accountPromises = (await wallet.eth.getAccounts()).map(
      async (addr) => ({
        addr,
        shortDisplay: walletConnectorService.getShortAccountAddr(addr),
      }),
    );

    const accounts = await Promise.all(accountPromises);

    dispatch(connectWalletSuccess({ accounts, walletType, chainId }));
    return [wallet];
  };
};

export const connectWalletSuccess = ({ accounts, walletType, chainId }) => ({
  type: types.CONNECT_WALLET_SUCCESS,
  accounts,
  walletType,
  chainId,
});

export const connectWalletError = () => ({
  type: types.CONNECT_WALLET_ERROR,
});

export const setConnectedWalletAccountChangeWatcher = ({ walletType }) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_CONNECTED_WALLET_ACCOUNT_CHANGE_WATCHER,
      walletType,
    });

    await walletConnectorService.connectors[walletType].addAccountChangeWatcher(
      async (accounts) => {
        const addr = accounts[0];
        if (!addr) {
          dispatch({ type: types.ACCOUNT_CHANGED, newAccount: {} });
          localStorage.setItem("session:wallet", null);
        } else {
          const newAccount = {
            addr,
            shortDisplay: walletConnectorService.getShortAccountAddr(addr),
          };
          dispatch({ type: types.ACCOUNT_CHANGED, newAccount });
        }
      },
    );
  };
};

export const addTokenToWallet = ({
  walletType,
  chainId,
  tokenType,
  address,
  symbol,
  decimals,
  image,
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.ADD_TOKEN_TO_WALLET,
      walletType,
      chainId,
      tokenType,
      address,
      symbol,
      decimals,
      image,
    });

    // First ensure we are connected to the correct network on the wallet app
    await walletConnectorService.connectors[walletType].connect(chainId);

    const [isAdded, error] = await walletConnectorService.connectors[
      walletType
    ].addToken({
      tokenType,
      address,
      symbol,
      decimals,
      image,
    });

    if (error) {
      dispatch({ type: types.ADD_TOKEN_TO_WALLET_ERROR, error });
      return [undefined, error];
    }

    dispatch({ type: types.ADD_TOKEN_TO_WALLET_SUCCESS });
    return [isAdded];
  };
};
