import Layout from "./layout/layout";
import HomeView from "./views/home/homeView";

export const AppRoutesConfig = {
  layoutComponent: Layout,
  routes: [
    {
      path: "/",
      component: HomeView,
    },
  ],
};
