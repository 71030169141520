import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider"
import { getChainDetails } from "../walletConnectorService";
import * as Sentry from "@sentry/browser";

const getErrorObject = (code, message) => ({
  code,
  message,
});

const delay = (time) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), time);
  });

export class MetaMask {
  metaMaskDelayTime = 1e3;
  web3;
  provider;

  async connect(chainId) {
    this.provider = await detectEthereumProvider();

    if (!this.provider) {
      const errorObject = getErrorObject(
        "wallet_not_installed",
        "MetaMask extension is not installed!",
      );

      return [undefined, errorObject];
    }

    console.log("Provider", this.provider);

    this.web3 = new Web3(this.provider);

    const [isMetaMaskConnected] = await this.isConnected(chainId);

    if (isMetaMaskConnected) {
      return [this.web3];
    }

    // Switch to the requested chain. If the target chain is the currently
    // connected chain in MetaMask then MetaMask will just ignore and show the
    // connection request that follows here
    let [, error] = await this.switchNetwork(chainId);

    if (error) {
      return [undefined, error];
    }

    // Add a delay of 1 second to force MetaMask to reopen again it's popup.
    // Without the delay the user will have to open MetaMask manually to accept
    // the connection
    await delay(this.metaMaskDelayTime);

    [, error] = await this.sendMetaMaskRequest({
      method: "eth_requestAccounts",
    });

    if (error) {
      return [undefined, error];
    }

    return [this.web3];
  }

  async isConnected(chainId) {
    const currentChainId = await this.web3.eth.net.getId();

    if (currentChainId !== chainId) {
      return [];
    }

    const accounts = await this.web3.eth.getAccounts();

    if (!accounts.length) {
      return [];
    }

    return [this.web3];
  }

  async switchNetwork(chainId) {
    let [, error] = await this.addNetwork(chainId);

    if (error) {
      return [undefined, error];
    }

    let currentChainId = await this.web3.eth.net.getId();

    // The following check is added because the user could add the network
    // but still don't switch to it, so we explicitly ask again to switch
    if (currentChainId !== chainId) {
      await delay(this.metaMaskDelayTime);

      [, error] = await this.sendMetaMaskRequest({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: this.web3.utils.numberToHex(chainId) }],
      });

      if (error) {
        return [undefined, error];
      }
    }

    currentChainId = await this.web3.eth.net.getId();

    if (currentChainId !== chainId) {
      const errorObject = getErrorObject(
        "switch_failed",
        "Please switch the network",
      );

      return [false, errorObject];
    }

    return [true];
  }

  async addNetwork(chainId) {
    const chainDetails = await getChainDetails(chainId);

    if (!chainDetails) {
      const errorObject = getErrorObject(
        "chain_id_not_found",
        `Couldn't find chainId: "${chainId}" in the list https://chainid.network/chains.json`,
      );

      return [false, errorObject];
    }

    try {
      console.log("MetaMask switch network request", chainId);
      await this.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: this.web3.utils.numberToHex(chainId) }],
      });
    } catch (switchError) {
      if (switchError.code === 4902 || switchError.message.includes("Unrecognized chain")) {
        const metaMaskChainDetails = {
          chainId: this.web3.utils.numberToHex(chainId), // Convert decimal to hex
          chainName: chainDetails.name,
          nativeCurrency: chainDetails.nativeCurrency,
          rpcUrls: chainDetails.rpc.filter(url => !url.includes("INFURA_API_KEY")),
          blockExplorerUrls: (chainDetails.explorers !== undefined) ? chainDetails.explorers.map((explorer) => explorer.url) : undefined,
          iconUrls: [],
        };
        const metaMaskRequest = {
          method: "wallet_addEthereumChain",
          params: [metaMaskChainDetails],
        };

        console.log("MetaMask Change Network Request", metaMaskRequest);

        let [, error] = await this.sendMetaMaskRequest(metaMaskRequest);

        if (error) {
          return [undefined, error];
        }
      }
      Sentry.captureException(switchError);
      return [undefined, getErrorObject(
        "network_change_error",
        switchError.message,
      )]
    }

    return [true];
  }

  async addToken({ tokenType, address, symbol, decimals, image }) {
    return await this.sendMetaMaskRequest({
      method: "wallet_watchAsset",
      params: {
        type: tokenType,
        options: {
          address: address,
          symbol: symbol,
          decimals: decimals,
          image: image,
        },
      },
    });
  }

  addAccountChangeWatcher(callback) {
    this.provider.on("accountsChanged", callback);
  }

  async sendMetaMaskRequest(request) {
    try {
      return [await this.provider.request(request)];
    } catch (err) {
      let errorObject;
      if (err.code === 4001) {
        errorObject = getErrorObject(
          "user_rejected_the_request",
          "User rejected the request.",
        );
      } else {
        errorObject = getErrorObject("unknown_error", err.message);
      }

      return [undefined, errorObject];
    }
  }
}
