export const callUntilResult = async (
  bindedMethod,
  shouldStopChecker,
  delay,
) => {
  return new Promise((resolve) => {
    const intervalHandle = setInterval(async () => {
      const result = await bindedMethod();

      if (shouldStopChecker(result)) {
        resolve(result);
        clearInterval(intervalHandle);
      }
    }, delay);
  });
};


// FIXME: Fix this shit later after prod
export const getOltHardcodedToken = (tokens = [], chainId) => {
  let token = null;
  if ([311752642, 4216137055].includes(chainId) && tokens.length > 0) {
    token = tokens.find((token) => {
      if (!token.extensions.bridgeInfo[chainId]) return false;
      return token.extensions.bridgeInfo[chainId].tokenAddress === "0x0000000000000000000000000000000000000000" && token.symbol === "sOLT"
    });
  } else {
    token = tokens.find((token) => token.symbol === "sOLT" && token.chainId === chainId);
  }
  return token || null;
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}