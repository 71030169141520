import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  hideTransactionsViewModal,
  startTransfer,
  transferSteps,
} from "../homeViewActions";

const TransactionsViewModal = (props) => {
  const {
    isModalActive,
    modalTitle,
    transactionsList,
    connectedWalletType,
    currentWalletAccount,
    startTransfer,
    hideTransactionsViewModal,
    currentToken,
  } = props;

  const getContinuationMethod = (transaction) => () =>
    startTransfer({
      walletType: connectedWalletType,
      sourceNetwork: transaction.sourceNetwork,
      targetNetwork: transaction.targetNetwork,
      accountAddress: currentWalletAccount.addr,
      transferAmount: transaction.amount,
      token: currentToken,
      context: { enterTxDetails: { transactionHash: transaction.sourceTx } },
      startingStep: transferSteps.cosigners,
      // Allow user to return back to the transactions list modal after finishing
      // an old/unfinished transaction
      showTransactionsViewModalWhenDone: true,
    });

  const getTransactionsTableRows = (transactionsList) =>
    transactionsList.map((transaction) => (
      <tr key={transaction.sourceTx}>
        <td>
          <i
            className={
              `fa ${transaction.status === "Success"
                ? "fa-check is-light-green"
                : "fa fa-minus-square is-light-red"
              }`}
            title={transaction.status}
          ></i>
        </td>
        <td>{transaction.amount} OLT</td>
        <td>
          {transaction.sourceNetwork.name}&nbsp;
          <i className="fa fa-arrow-right is-network-direction-arrow"></i>
          &nbsp;{transaction.targetNetwork.name}
        </td>
        <td>{transaction.date.formatted}</td>
        <td>
          <button
            className={
              `button is-info is-outlined is-small ${transaction.status === "Pending" ? "" : "is-hidden"
              }`}
            title="Continue the Transaction"
            disabled={!currentToken}
            onClick={getContinuationMethod(transaction)}
          >
            Continue
          </button>
        </td>
      </tr>
    ));

  return (
    <div className={`modal ${isModalActive ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{modalTitle}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={hideTransactionsViewModal}
          ></button>
        </header>
        <section className="modal-card-body">
          <table className="table is-hoverable is-fullwidth">
            <tbody>{getTransactionsTableRows(transactionsList)}</tbody>
          </table>
        </section>
        <footer className="modal-card-foot is-justify-content-right">
          <button className="button" onClick={hideTransactionsViewModal}>
            Close
          </button>
        </footer>
      </div>
    </div>
  );
};

TransactionsViewModal.propTypes = {
  isModalActive: PropTypes.bool,
  modalTitle: PropTypes.string,
  transactionsList: PropTypes.array,
  connectedWalletType: PropTypes.string,
  currentWalletAccount: PropTypes.shape({
    addr: PropTypes.string,
    shortDisplay: PropTypes.string,
    rawBalance: PropTypes.string,
    balance: PropTypes.number,
    balanceWithCurrencyFormatted: PropTypes.string,
  }),
  startTransfer: PropTypes.func,
  hideTransactionsViewModal: PropTypes.func,
  currentToken: PropTypes.object,
};

export default connect(
  (state) => ({
    isModalActive: state.homeView.transactionsViewModal.isModalActive,
    modalTitle: state.homeView.transactionsViewModal.modalTitle,
    transactionsList: state.homeView.transactionsViewModal.transactionsList,
    connectedWalletType: state.walletConnector.walletType,
    currentWalletAccount: state.walletConnector.currentWalletAccount,
  }),
  (dispatch) => ({
    ...bindActionCreators(
      {
        startTransfer,
        hideTransactionsViewModal,
      },
      dispatch,
    ),
  }),
)(TransactionsViewModal);
