import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import reportWebVitals from "./reportWebVitals";
import Router from "./router";
import configureStore from "./store/configureStore";
import * as env from "./env";
import GlobalBlockingSpinner from "./components/globalBlockingSpinner/globalBlockingSpinner";

const store = configureStore();

if (env.sentryDSN) {
  Sentry.init({
    dsn: env.sentryDSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router />
      <GlobalBlockingSpinner />
      <Toaster
        position="top-right"
        toastOptions={{
          duration: env.notificationTimeOut,
          style: {
            border: "1px solid rgb(0 161 255)",
            padding: "16px",
            color: "rgb(0 119 189)",
          },
        }}
      />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
