import { useEffect, useState } from "react";

const useCheckTouchScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    }
  }, []);

  return (width <= 1024);
}

export default useCheckTouchScreen;
