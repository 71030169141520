import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import "./homeView.scss";
import {
  getAppConfig,
  getAccountBalanceOnBothNetworks,
  switchNetworks,
  getUsdValue,
  showTransferModal,
  getPastTransactions,
  showTransactionsViewModal,
  getBridgeTokens,
} from "./homeViewActions";
import {
  addTokenToWallet,
  setConnectedWalletAccountChangeWatcher,
  showWalletSelectionModal,
  connectWallet,
} from "../../components/walletConnector/walletConnectorActions";
import WalletConnector from "../../components/walletConnector/walletConnector";
import { useDebounce } from "react-use";
import TransferModal from "./_components/transferModal";
import * as globalBlockingSpinnerService from "../../components/globalBlockingSpinner/globalBlockingSpinnerService";
import TransactionsViewModal from "./_components/transactionsViewModal";
import { getOltHardcodedToken } from "../../services/utils";
import MetaMaskRoundLogo from "../../images/metamask.png";

const HomeView = (props) => {
  const {
    connectedWalletType,
    currentWalletAccount,
    isAccountChangeWatcherSet,
    appConfig,
    sourceNetwork,
    targetNetwork,
    isTransferBtnLoading,
    isTokenAdditionRequestPending,
    showWalletSelectionModal,
    getAppConfig,
    getAccountBalanceOnBothNetworks,
    switchNetworks,
    getUsdValue,
    showTransferModal,
    addTokenToWallet,
    getPastTransactions,
    setConnectedWalletAccountChangeWatcher,
    getBridgeTokens,
    tokens,
    connectWallet,
  } = props;
  const currentToken = getOltHardcodedToken(tokens, sourceNetwork.config.chain_id);
  console.log("currentToken", currentToken)
  const [userEnteredAmount, setUserEnteredAmount] = useState("");
  const [amountInUsd, setAmountInUsd] = useState(0);
  const [loading, setLoading] = useState(true);

  // const isSourceNetworkUsingOlt = sourceNetwork.config.symbol === "OLT";
  const isTargetNetworkUsingOlt = targetNetwork.config.symbol === "OLT";

  useEffect(() => {
    async function init() {
      globalBlockingSpinnerService.show();

      await getAppConfig();

      const sessionWallet = localStorage.getItem("session:wallet");
      if (sessionWallet) {
        const wallet = JSON.parse(sessionWallet);
        if (wallet)
          await connectWallet({ walletType: wallet.type, chainId: wallet.chainId });
      }

      globalBlockingSpinnerService.hide();

      setLoading(false);
    }

    init();
  }, [getAppConfig, showWalletSelectionModal, setLoading, connectWallet]);

  useEffect(() => {
    if (typeof appConfig === "undefined" || Object.keys(appConfig).length === 0) return;

    (async () => {
      await getBridgeTokens(appConfig);
    })()
  }, [appConfig, getBridgeTokens]);

  // Get total balances on both networks after connecting to the wallet
  // and every time the user switch networks as well
  useEffect(() => {
    if (!currentWalletAccount.addr) {
      return;
    }

    if (currentToken === null) {
      return;
    }

    if (!isAccountChangeWatcherSet && connectedWalletType) {
      // Set wallet account change watcher after connecting
      // to a wallet
      setConnectedWalletAccountChangeWatcher({
        walletType: connectedWalletType,
      });
    }

    getPastTransactions({
      accountAddr: currentWalletAccount.addr,
      sourceNetwork,
      targetNetwork,
    });

    getAccountBalanceOnBothNetworks({
      accountAddr: currentWalletAccount.addr,
      token: currentToken,
      sourceNetworkConfig: sourceNetwork.config,
      targetNetworkConfig: targetNetwork.config,
    });
  }, [currentToken, currentWalletAccount]);

  const [,] = useDebounce(
    async () => {
      let usdAmount = 0;

      if (userEnteredAmount > 0) {
        usdAmount = await getUsdValue(userEnteredAmount, "OLT");
      }

      setAmountInUsd(usdAmount.toFixed(2));
    },
    100,
    [userEnteredAmount],
  );

  function onUserEnteredAmountChange(e) {
    setUserEnteredAmount(e.target.value ? parseFloat(e.target.value) : "");
  }

  function onMaxAmountClick() {
    setUserEnteredAmount(sourceNetwork.balance);
  }

  async function onSwitchNetworkClick() {
    setUserEnteredAmount("");
    const [, error] = await switchNetworks(
      connectedWalletType,
      targetNetwork.config.chain_id,
    );

    if (error) {
      toast.error(error.message);
    }

    getAccountBalanceOnBothNetworks({
      accountAddr: currentWalletAccount.addr,
      token: currentToken,
      // At the moment of calling this methods the reducer didn't run yet
      // So we pass targetNetwork to sourceNetwork and so on to match what
      // Will be the case after the reducer run
      sourceNetworkConfig: targetNetwork.config,
      targetNetworkConfig: sourceNetwork.config,
    });
  }

  async function onTransferClick(e) {
    e.preventDefault();
    if (userEnteredAmount > sourceNetwork.balance) {
      toast.error("Amount cannot be larger than the balance!");
      return;
    }

    if (userEnteredAmount <= 0) {
      toast.error("Amount must be larger than 0!");
      return;
    }

    await showTransferModal({
      walletType: connectedWalletType,
      amount: userEnteredAmount,
      sourceNetwork,
      targetNetwork,
      walletAccount: currentWalletAccount,
      exitGasEstimate: appConfig.gas_estimate.exit,
      token: currentToken,
    });
  }

  async function addOltTokenToConnectedWallet(network) {
    addTokenToWallet({
      walletType: connectedWalletType,
      chainId: network.config.chain_id,
      // Constant as we only work with ERC20 at the moment also MetaMask support
      // ERC20 only currently
      tokenType: "ERC20",
      address: currentToken.address,
      symbol: currentToken.symbol,
      decimals: currentToken.decimals,
      image: currentToken.logoURI,
    });
  }

  return (
    <>
      <div className="columns is-centered notification-section">
        <div className="column is-half" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: "2.5rem" }}>
          <div className={"notification is-bridge-notification"}>
            <span style={{ fontWeight: 700, color: "white" }}>
              Syndicate Bridge<br></br>
              WARNING: The Syndicate Bridge is an experimental product that is pre-beta and considered highly risky. We are NOT responsible for any loss during the transfers.
              <br></br>PLEASE USE AT YOUR OWN RISK.
            </span>
          </div>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-half bridge-form-box">
          <div className={`from-section ${sourceNetwork.highlightClass}`}>
            <div className="columns is-centered from-network">
              <div className="column is-half">
                <h4 className="title is-4 has-text-centered">
                  <img
                    src={sourceNetwork.logoPath}
                    alt={sourceNetwork.name}
                    className="from-logo"
                  />
                </h4>
              </div>
            </div>
            <br />
            <div className="columns">
              <div className="column">
                <div className="field has-addons">
                  <div className="control is-expanded">
                    <input
                      className="input no-outline"
                      style={{
                        borderTopLeftRadius: "12px",
                        borderBottomLeftRadius: "12px",
                      }}
                      type="number"
                      step="any"
                      value={userEnteredAmount}
                      onChange={onUserEnteredAmountChange}
                      placeholder={currentWalletAccount.addr && "Exp.: 100.432"}
                    />
                  </div>
                  <p className="control">
                    <button
                      className="button is-static one-color-button-pressed"
                      type="button"
                    >
                      OLT
                    </button>
                  </p>
                  <p className="control">
                    <button
                      className="button one-color-button"
                      title="Set to Max Amount"
                      type="button"
                      onClick={onMaxAmountClick}
                      disabled={!currentWalletAccount.addr}
                      style={{
                        borderTopRightRadius: "12px",
                        borderBottomRightRadius: "12px",
                      }}
                    >
                      Max Amount
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="columns">
                  <div
                    className="column is-6 is-12-mobile has-text-left dollar-amount"
                    title={"$" + amountInUsd}
                  >
                    &nbsp;&nbsp;&nbsp;{currentWalletAccount.addr && `In USD: ${amountInUsd}`}<br></br>
                    &nbsp;&nbsp;&nbsp;{currentWalletAccount.addr && `Balance: ${sourceNetwork.balance} OLT`}
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className={`to-section ${targetNetwork.highlightClass}`}>
            <div className="has-text-centered switch-btn-wrapper">
              <button
                className="button is-rounded one-color-button"
                type="button"
                disabled={!currentWalletAccount.addr || !currentToken}
                onClick={onSwitchNetworkClick}
              >
                <span className="fa fa-sync alt"></span>&nbsp;&nbsp;Switch
              </button>
            </div>

            <div className="direction is-hidden-mobile">
              <span className="fa fa-reply fa-2x fa-rotate-270 is-network-direction-arrow"></span>
            </div>

            <div className="columns is-centered to-network">
              <div className="column is-half">
                <h4 className="title is-4 has-text-centered">
                  <img
                    src={targetNetwork.logoPath}
                    className="from-logo"
                    alt={targetNetwork.name}
                  />
                </h4>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="columns">
                  <div className="column has-text-right-desktop" style={{ textAlign: "center" }}>
                    {/* {connectedWalletType && `Balance: ${targetNetwork.balance} OLT`} */}
                    <button
                      className={`button one-color-button is-small ${!currentWalletAccount.addr ? "is-hidden" : ""} ${isTokenAdditionRequestPending ? "is-loading" : ""}`}
                      type="button"
                      disabled={!currentWalletAccount.addr || isTargetNetworkUsingOlt}
                      style={{
                        borderRadius: "8px"
                      }}
                      onClick={() =>
                        addOltTokenToConnectedWallet(targetNetwork)
                      }
                    >
                      <img src={MetaMaskRoundLogo} width="16" alt="MetaMask Logo" />&nbsp;&nbsp;Add sOLT
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                {currentWalletAccount.addr ? (
                  <button
                    className={`button one-oposite-color-button is-medium is-fullwidth ${isTransferBtnLoading || loading ? "is-loading" : ""}`}
                    style={{
                      borderRadius: "16px"
                    }}
                    type="submit"
                    onClick={onTransferClick}
                  >
                    Transfer
                  </button>
                ) : (
                  <button
                    className={`button is-warning is-medium is-fullwidth ${isTransferBtnLoading || loading ? "is-loading" : ""}`}
                    style={{
                      borderRadius: "16px"
                    }}
                    type="submit"
                    title="Connect to your wallet"
                    onClick={(e) => {
                      e.preventDefault();
                      showWalletSelectionModal(appConfig.networks[0].source.chain_id);
                    }}
                  >
                    Connect wallet
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <WalletConnector />
      <TransferModal currentToken={currentToken} />
      <TransactionsViewModal currentToken={currentToken} />
    </>
  );
};

HomeView.propTypes = {
  connectedWalletType: PropTypes.string,
  currentWalletAccount: PropTypes.shape({
    addr: PropTypes.string,
    shortDisplay: PropTypes.string,
    rawBalance: PropTypes.string,
    balance: PropTypes.number,
    balanceWithCurrencyFormatted: PropTypes.string,
  }),
  isAccountChangeWatcherSet: PropTypes.bool,
  appConfig: PropTypes.object,
  sourceNetwork: PropTypes.shape({
    config: PropTypes.object,
    name: PropTypes.string,
    logoPath: PropTypes.string,
    highlightClass: PropTypes.string,
    balance: PropTypes.number,
  }),
  targetNetwork: PropTypes.shape({
    config: PropTypes.object,
    name: PropTypes.string,
    logoPath: PropTypes.string,
    highlightClass: PropTypes.string,
    balance: PropTypes.number,
  }),
  isTransferBtnLoading: PropTypes.bool,
  isTokenAdditionRequestPending: PropTypes.bool,
  showWalletSelectionModal: PropTypes.func,
  getAppConfig: PropTypes.func,
  getAccountBalanceOnBothNetworks: PropTypes.func,
  switchNetworks: PropTypes.func,
  getUsdValue: PropTypes.func,
  showTransferModal: PropTypes.func,
  addTokenToWallet: PropTypes.func,
  connectWallet: PropTypes.func,
  getPastTransactions: PropTypes.func,
  showTransactionsViewModal: PropTypes.func,
  setConnectedWalletAccountChangeWatcher: PropTypes.func,
  currentToken: PropTypes.object,
  getBridgeTokens: PropTypes.func,
  tokens: PropTypes.array,
};

export default connect(
  (state) => ({
    connectedWalletType: state.walletConnector.walletType,
    currentWalletAccount: state.walletConnector.currentWalletAccount,
    isAccountChangeWatcherSet: state.walletConnector.unfinishedTransactions,
    appConfig: state.homeView.appConfig,
    sourceNetwork: state.homeView.sourceNetwork,
    targetNetwork: state.homeView.targetNetwork,
    isTransferBtnLoading: state.homeView.isTransferBtnLoading,
    isTokenAdditionRequestPending: state.homeView.isTokenAdditionRequestPending,
    tokens: state.homeView.tokens,
  }),
  (dispatch) => ({
    ...bindActionCreators(
      {
        showWalletSelectionModal,
        getAppConfig,
        getAccountBalanceOnBothNetworks,
        switchNetworks,
        getUsdValue,
        showTransferModal,
        addTokenToWallet,
        getPastTransactions,
        showTransactionsViewModal,
        setConnectedWalletAccountChangeWatcher,
        getBridgeTokens,
        connectWallet,
      },
      dispatch,
    ),
  }),
)(HomeView);
