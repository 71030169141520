import React from "react";
import "./globalBlockingSpinner.scss";

const GlobalBlockingSpinner = () => {
  return (
    <div className={"global-blocking-spinner is-hidden"}>
      <img
        src="bridgeLoadingSpinner.svg"
        className="fa-spin"
        alt="Loading Spinner"
      />
    </div>
  );
};

export default GlobalBlockingSpinner;
