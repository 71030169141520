import React from "react";
import PropTypes from "prop-types";
import "./layout.scss";
import TopNavbar from "./_components/topNavbar";

const Layout = (props) => {
  const { children = {} } = props;
  return (
    <>
      <TopNavbar />
      <section className="section">
        <div className="container main-container">{children}</div>
      </section>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.array,
};

export default Layout;
