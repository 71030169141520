const walletConnectorInitialState = {
  // Web3 wallet
  wallet: undefined,
  /*
    {
      addr,
      shortDisplay,
      rawBalance,
      balance,
      balanceWithCurrencyFormatted,
    }
   */
  currentWalletAccount: {},
  // Array of objects with same format as `currentWalletAccount`
  accounts: [],
  // Flag to prevent mistakenly setting account change watcher more than once
  isAccountChangeWatcherSet: false,
  walletType: "",
  chainId: undefined,
  isWalletSelectionModalActive: false,
  walletConnectionState: {
    isConnectionPending: false,
    walletType: "",
  },
};

export default walletConnectorInitialState;
