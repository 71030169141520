import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  hideTransferModal,
  transferSteps,
  showTransactionsViewModal,
  getPastTransactions,
  startTransfer,
} from "../homeViewActions";

const TransferModal = (props) => {
  const {
    isTransferModalActive,
    isTransferProcessOngoing,
    unfinishedTransactions,
    isTransferComplete,
    transferErr,
    currentTransferContext,
    currentTransferStep,
    transferStatus,
    showTransactionsViewModalWhenDone,
    sourceNetwork,
    targetNetwork,
    connectedWalletType,
    accountAddress,
    hideTransferModal,
    showTransactionsViewModal,
    getPastTransactions,
    startTransfer,
    currentToken,
  } = props;

  const isRemainingAmountPositive = transferStatus.amountAfterFees > 0;

  const onStartTransfer = () => {
    startTransfer({
      walletType: connectedWalletType,
      sourceNetwork,
      targetNetwork,
      accountAddress,
      transferAmount: transferStatus.amount,
      token: currentToken,
    });
  };

  const onResumeTransfer = () => {
    startTransfer({
      walletType: connectedWalletType,
      sourceNetwork,
      targetNetwork,
      accountAddress,
      transferAmount: transferStatus.amount,
      token: currentToken,
      context: currentTransferContext,
      startingStep: currentTransferStep,
    });
  };

  // Get the styling classes if the passed in step equals the current active
  // step
  const getMatchingStepClasses = (step) => {
    if (transferStatus.currentStep !== step) {
      return;
    }

    const classes = ["is-active"];

    if (transferStatus.isStepLoading) {
      classes.push("spin");
    }

    return classes.join(" ");
  };

  function getMatchingStepContentClasses(step) {
    if (transferStatus.currentStep !== step) {
      return "is-hidden";
    }

    return "is-active";
  }

  const onHideTransferModal = () => {
    if (showTransactionsViewModalWhenDone) {
      showTransactionsViewModal({
        title: "Unfinished Transactions",
        transactionsList: unfinishedTransactions,
      });
    }

    hideTransferModal();

    // Update past transactions so if the user canceled a transaction without
    // finishing it, he will see an increase count of unfinished transactions
    getPastTransactions({
      accountAddr: accountAddress,
      sourceNetwork: sourceNetwork,
      targetNetwork: targetNetwork,
    });
  };

  return (
    <div
      className={
        `modal transfer-modal ${isTransferModalActive ? "is-active" : ""
        }`}
    >
      <div className="modal-background"></div>
      <div className="modal-card">
        <div
          className={`ribbon ribbon-top-right ${getMatchingStepContentClasses(
            transferSteps.done,
          )}`}
        >
          <span>Done</span>
        </div>
        <header className="modal-card-head">
          <p className="modal-card-title">
            Transfer from <strong>{transferStatus.sourceNetworkName}</strong> to
            &nbsp;<strong>{transferStatus.targetNetworkName}</strong>
          </p>
          {transferStatus.currentStep != transferSteps.done && (
            <button className="delete" aria-label="close" onClick={onHideTransferModal}></button>
          )}
        </header>
        <section className="modal-card-body">
          <div
            className={
              `notification is-danger is-light ${isRemainingAmountPositive ? "is-hidden" : ""
              }`}
          >
            Nothing is remaining after subtracting the fees. Please increase
            &nbsp;the amount to transfer.
          </div>
          <ul className="steps has-content-centered">
            <li
              className={`steps-segment ${getMatchingStepClasses(
                transferSteps.review,
              )}`}
            >
              <span className="steps-marker">1</span>
              <span className="steps-marker-spinner"></span>
              <div className="steps-content">
                <p className="is-size-6">Review</p>
              </div>
            </li>
            <li
              className={`steps-segment ${getMatchingStepClasses(
                transferSteps.sourceNetwork,
              )}`}
            >
              <span className="steps-marker">2</span>
              <span className="steps-marker-spinner"></span>
              <div className="steps-content">
                <p className="is-size-6">{transferStatus.sourceNetworkName}</p>
              </div>
            </li>
            <li
              className={`steps-segment ${getMatchingStepClasses(
                transferSteps.cosigners,
              )}`}
            >
              <span className="steps-marker">3</span>
              <span className="steps-marker-spinner"></span>
              <div className="steps-content">
                <p className="is-size-6">Cosigners</p>
              </div>
            </li>
            <li
              className={`steps-segment ${getMatchingStepClasses(
                transferSteps.targetNetwork,
              )}`}
            >
              <span className="steps-marker">4</span>
              <span className="steps-marker-spinner"></span>
              <div className="steps-content">
                <p className="is-size-6">{transferStatus.targetNetworkName}</p>
              </div>
            </li>
            <li
              className={`steps-segment ${getMatchingStepClasses(
                transferSteps.confirmations,
              )}`}
            >
              <span className="steps-marker">5</span>
              <span className="steps-marker-spinner"></span>
              <div className="steps-content">
                <p className="is-size-6">Confirmations</p>
              </div>
            </li>
            <li
              className={`steps-segment ${getMatchingStepClasses(
                transferSteps.done,
              )}`}
            >
              <span className="steps-marker">6</span>
              <span className="steps-marker-spinner"></span>
              <div className="steps-content">
                <p className="is-size-6">Done</p>
              </div>
            </li>
          </ul>
          <div className="columns is-justify-content-center">
            <div className="column is-half has-text-centered" style={{ padding: "0!important" }}>
              <div
                className={getMatchingStepContentClasses(transferSteps.review)}
              >
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="has-text-weight-bold">Amount:</td>
                      <td>
                        {transferStatus.amount} OLT <br />
                        <span className="is-italic is-size-7">
                          (${transferStatus.amountInUsd})
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="has-text-weight-bold">Fees:</td>
                      <td>
                        {transferStatus.sourceNetworkFee} {sourceNetwork.config.symbol} + {transferStatus.targetNetworkFee} {targetNetwork.config.symbol} <br />
                        <span className="is-italic is-size-7">
                          (${transferStatus.totalFeesInUsd})
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className={getMatchingStepContentClasses(
                  transferSteps.sourceNetwork,
                )}
              >
                <img
                  src={sourceNetwork.logoPath}
                  width="180"
                  alt={sourceNetwork.name}
                />
                <br />
                <br />
                <p className="has-text-grey">
                  Initiating the changes on {sourceNetwork.name}...
                </p>
              </div>
              <div
                className={getMatchingStepContentClasses(
                  transferSteps.cosigners,
                )}
              >
                <i className="fa fa-file-signature fa-4x"></i>
                <br />
                <br />
                <p className="has-text-grey">Waiting for Cosigners ({sourceNetwork.confirmations} confirmations)...</p>
              </div>
              <div
                className={getMatchingStepContentClasses(
                  transferSteps.targetNetwork,
                )}
              >
                <img
                  src={targetNetwork.logoPath}
                  width="180"
                  alt={targetNetwork.name}
                />
                <br />
                <br />
                <p className="has-text-grey">
                  Initiating the changes on {targetNetwork.name}...
                </p>
              </div>
              <div
                className={getMatchingStepContentClasses(
                  transferSteps.confirmations,
                )}
              >
                <i className="fa fa-money-bill-wave fa-4x"></i>
                <br />
                <br />
                <p className="has-text-grey">Waiting for Confirmations...</p>
              </div>
              <div
                className={getMatchingStepContentClasses(transferSteps.done)}
              >
                <i className="fa fa-check fa-4x is-light-green"></i>
                <br />
                <br />
                <p className="has-text-grey">All Done!</p>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot no-flex-modal-foot">
          <div className="columns">
            <div className="column">
              <p
                className={
                  `is-justify-content-flex-start has-text-danger ${transferErr ? "" : "is-hidden"
                  }`}
              >
                <strong className="has-text-danger">Error:</strong>&nbsp;
                {transferErr && transferErr.message}
              </p>
            </div>
            <div className="column has-text-right">
              {(transferStatus.currentStep === transferSteps.cosigners && isTransferProcessOngoing) && (
                <button
                  className={"button"}
                  style={{
                    borderRadius: "16px"
                  }}
                  onClick={onHideTransferModal}
                >
                  Resume later
                </button>
              )}
              <button
                className={
                  `button ${isTransferProcessOngoing && !transferErr ? "is-hidden" : ""
                  }`}
                style={{
                  borderRadius: "16px"
                }}
                onClick={onHideTransferModal}
              >
                {isTransferComplete ? "Close" : "Cancel"}
              </button>
              <button
                className={
                  `button one-color-button ${isTransferProcessOngoing ? " is-loading" : ""
                  }
                  ${isTransferComplete || transferErr ? " is-hidden" : ""}
                `}
                style={{
                  borderRadius: "16px"
                }}
                disabled={
                  isTransferProcessOngoing || !isRemainingAmountPositive
                }
                onClick={onStartTransfer}
              >
                Transfer
              </button>
              <button
                className={`button one-color-button ${transferErr ? "" : "is-hidden"}
                `}
                style={{
                  borderRadius: "16px"
                }}
                onClick={onResumeTransfer}
              >
                Retry
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

TransferModal.propTypes = {
  isTransferModalActive: PropTypes.bool,
  transferErr: PropTypes.object,
  currentTransferContext: PropTypes.object,
  currentTransferStep: PropTypes.string,
  isTransferProcessOngoing: PropTypes.bool,
  isTransferComplete: PropTypes.bool,
  transferStatus: PropTypes.object,
  showTransactionsViewModalWhenDone: PropTypes.bool,
  sourceNetwork: PropTypes.object,
  targetNetwork: PropTypes.object,
  accountAddress: PropTypes.string,
  connectedWalletType: PropTypes.string,
  unfinishedTransactions: PropTypes.array,
  hideTransferModal: PropTypes.func,
  showTransactionsViewModal: PropTypes.func,
  getPastTransactions: PropTypes.func,
  startTransfer: PropTypes.func,
  currentToken: PropTypes.object,
};

export default connect(
  (state) => ({
    isTransferModalActive: state.homeView.transferStatus.isTransferModalActive,
    transferErr: state.homeView.transferStatus.transferErr,
    currentTransferContext:
      state.homeView.transferStatus.currentTransferContext,
    currentTransferStep: state.homeView.transferStatus.currentStep,
    isTransferProcessOngoing:
      state.homeView.transferStatus.isTransferProcessOngoing,
    isTransferComplete: state.homeView.transferStatus.isTransferComplete,
    transferStatus: state.homeView.transferStatus,
    showTransactionsViewModalWhenDone:
      state.homeView.transferStatus.showTransactionsViewModalWhenDone,
    sourceNetwork: state.homeView.sourceNetwork,
    targetNetwork: state.homeView.targetNetwork,
    accountAddress: state.walletConnector.currentWalletAccount.addr,
    connectedWalletType: state.walletConnector.walletType,
    unfinishedTransactions: state.homeView.unfinishedTransactions,
  }),
  (dispatch) => ({
    ...bindActionCreators(
      {
        hideTransferModal,
        showTransactionsViewModal,
        getPastTransactions,
        startTransfer,
      },
      dispatch,
    ),
  }),
)(TransferModal);
