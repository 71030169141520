export const SHOW_WALLET_SELECTION_MODAL =
  "WALLET_CONNECTOR/SHOW_WALLET_SELECTION_MODAL";
export const HIDE_WALLET_SELECTION_MODAL =
  "WALLET_CONNECTOR/HIDE_WALLET_SELECTION_MODAL";
export const CONNECT_WALLET = "WALLET_CONNECTOR/CONNECT_WALLET";
export const CONNECT_WALLET_SUCCESS = "WALLET_CONNECTOR/CONNECT_WALLET_SUCCESS";
export const CONNECT_WALLET_ERROR = "WALLET_CONNECTOR/CONNECT_WALLET_ERROR";
export const ADD_TOKEN_TO_WALLET = "WALLET_CONNECTOR/ADD_TOKEN_TO_WALLET";
export const ADD_TOKEN_TO_WALLET_SUCCESS =
  "WALLET_CONNECTOR/ADD_TOKEN_TO_WALLET_SUCCESS";
export const ADD_TOKEN_TO_WALLET_ERROR = "WALLET_CONNECTOR/ADD_TOKEN_TO_ERROR";
export const SET_CONNECTED_WALLET_ACCOUNT_CHANGE_WATCHER =
  "HOME_VIEW/SET_CONNECTED_WALLET_ACCOUNT_CHANGE_WATCHER";
export const ACCOUNT_CHANGED = "HOME_VIEW/ACCOUNT_CHANGED";
